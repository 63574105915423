import React, { useContext, useEffect } from "react";
import { Form, Input, message } from "antd";
import { observer } from "mobx-react";

import "./style.scss";
import { useHistory } from "react-router-dom";
import { Button } from "..";
import authStore from "../../../stores/authStore";
interface Props {
  test?: any;
}

const Authentication: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const { params } = useHistory().location;
  const { url } = params ? params : { url: "" };
  const { onAuthSubmit, onAuthSendCode, time } = useContext(authStore);
  // const captchaRef = useRef(null);

  // eslint-disable-next-line no-unused-vars
  let captcha;
  const getInstance = instance => {
    captcha = instance;
  };

  const sendDecorator = async () => {
    const mobile = form.getFieldValue("mobile");
    if (!mobile || !/^1[3456789]\d{9}$/.test(mobile)) {
      message.error("请输入正确的手机号码");
    } else {
      (document.querySelector("#captcha-button") as any).click();
    }
  };

  const captchaVerifyCallback = async captchaVerifyParam => {
    let result: any = {};
    let error = false;
    try {
      const values: any = form.getFieldsValue();
      const { mobile } = values;
      result = await onAuthSendCode({ captchaVerifyParam, mobile });
    } catch (e) {
      error = e;
    }

    console.log("result:", result);

    if (error) {
      captcha.refresh();
      captcha.hide();
    } else {
      return {
        captchaResult: result.captchaResult, // 验证码验证是否通过，boolean类型，必选 result.captchaVerifyResult
        bizResult: result.bizResult, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      };
    }
  };

  // 验证通过后调用
  const onBizResultCallback = bizResult => {
    console.log("bizResult:", bizResult);
  };

  useEffect(() => {
    // 只用初始化一次验证码即可
    (window as any).initAliyunCaptcha({
      SceneId: "nepl2ukj", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: "mk2426", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: "#captcha-element", // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: "#captcha-button", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 360,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    });
    return () => {
      // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
      document.getElementById("aliyunCaptcha-mask")?.remove();
      document.getElementById("aliyunCaptcha-window-popup")?.remove();
    };
  }, []);

  return (
    <div
      className="pro-authentication"
      onClick={event => event.stopPropagation()}
    >
      <Form form={form} onFinish={values => onAuthSubmit(values, url)}>
        <h2>实名认证</h2>
        <h3>根据国家法律法规要求，绑定手机号获得更高级的安全保护。</h3>
        <Form.Item
          hasFeedback
          rules={[
            { required: true, message: "请输入正确的手机号码" },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: "请输入正确的手机号码",
            },
          ]}
          name="mobile"
        >
          <Input placeholder="手机号码" />
        </Form.Item>
        {/* <div className="pro-authentication__code">
          <Form.Item
            hasFeedback
            rules={[
              { required: true, message: "请输入正确的验证码" },
              { min: 5, message: "验证码是 5 位" },
            ]}
            name="_rucaptcha"
          >
            <Input autoComplete="off" placeholder="图形验证码" />
          </Form.Item>
          <img
            className="pro-authentication__captcha"
            ref={captchaRef}
            src="/rucaptcha/"
            alt="图形验证码"
            onClick={e => updateCaptcha(captchaRef.current, e)}
          />
        </div> */}
        <div className="pro-authentication__code">
          <Form.Item
            hasFeedback
            rules={[{ required: true, message: "请输入正确的短信验证码" }]}
            name="phone_verify_code"
          >
            <Input autoComplete="off" placeholder="验证码" />
          </Form.Item>
          <div
            className={`send-code ${time > 0 && "send-code--time"}`}
            onClick={() => {
              if (time <= 0) {
                sendDecorator();
              }
            }}
          >
            {time > 0 ? `${time}s 后重新发送` : "发送验证码"}
          </div>
          <div id="captcha-button" />
          <div id="captcha-element" />
        </div>
        <Form.Item className="pro-authentication__submit">
          <Button isFullWidth size="large" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default observer(Authentication);
